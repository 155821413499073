<script>
  import { mdLinksToHtml } from './utils';

  export let teaching = [];

  const ie11 = !!window.MSInputMethodContext && !!document.documentMode;

  function parse(_teaching) {
    const years = {};
    return _teaching.map((teaching) => {
      const parsedTeaching = { ...teaching };
      if (!years[parsedTeaching.year]) {
        parsedTeaching.showYear = true;
        years[parsedTeaching.year] = true;
      }
      parsedTeaching.class = mdLinksToHtml(parsedTeaching.class);
      return parsedTeaching;
    });
  };

  let parsedTeaching = [];
  $: parsedTeaching = parse(teaching);
</script>

<style lang="scss">
  @import 'globals';

  ol {
    margin: 0;
    padding: 0;
  }

  .teaching :global(a) {
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    border-radius: 1em;
    text-decoration: underline solid var(--color-033);
    transition: color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
                background 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .teaching :global(a):hover,
  .teaching :global(a):focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: var(--color);
  }

  .teaching > li {
    list-style: none;
    margin-top: 0.75rem;
  }

  .teaching > li:first-child {
    margin-top: 0;
  }

  .teaching > li.v-space {
    margin-top: 2rem;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    .wrapper {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;
      grid-template-columns: auto;
    }
  }

  .teaching > li:before {
    display: block;
    content: " ";
    margin-top: -4rem;
    height: 4rem;
    visibility: hidden;
  }

  .year {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .ie11 .year {
    display: block;
    width: 6rem;
    margin-right: 1rem;
  }

  .ie11 .body {
    min-width: 20rem;
    width: calc(100% - 7rem);
  }

  .body {
    position: relative;
  }

  .title:before {
    content: '"';
  }

  .title:after {
    content: '"';
  }

  .host:before {
    content: 'Host: ';
  }

  .date:before {
    content: '(';
  }

  .date:after {
    content: ')';
  }

  .comma-after:after {
    content: ',';
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    @supports (display: grid) {
      .wrapper {
        grid-template-columns: 6rem auto;
      }
    }
  }
</style>

<ol class="teaching" class:ie11>
  {#each parsedTeaching as teaching}
    <li class:v-space={teaching.showYear}>
      <div class="wrapper">
        <div class="year">
          {#if teaching.showYear}
            {teaching.year}
          {/if}
        </div>
        <div class="body">
          <strong class="comma-after">{teaching.type}</strong>
          {#if teaching.title}
            <span class="title comma-after">{teaching.title}</span>
          {/if}
          <span class="class comma-after">{@html teaching.class}</span>
          <span class="institute comma-after">{teaching.institute}</span>
          {#if teaching.location}
            <span class="location comma-after">{teaching.location}</span>
          {/if}
          {#if teaching.term}
            <span class="term comma-after">{teaching.term}</span>
          {/if}
          {#if teaching.host}
            <span class="host comma-after">{teaching.host}</span>
          {/if}
          <time class="date">{teaching.month} {teaching.year}</time>
        </div>
      </div>
    </li>
  {/each}
</ol>

<script>
  import { setContext } from 'svelte';
  import { loadImage } from './utils.js';

  let lazyImageObserver;
  let lazyImageResolvers = new Map();

  if ('IntersectionObserver' in window) {
    lazyImageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          let element = entry.target;
          loadImage(element, element.dataset.img, element.dataset.webp);
          lazyImageObserver.unobserve(element);
          if (lazyImageResolvers.has(element)) {
            lazyImageResolvers.get(element)();
            lazyImageResolvers.delete(element);
          }
        }
      });
    });
  }

  const load = (element, img, webp) => new Promise((resolve) => {
    const resolver = () => { resolve(); };
    if (lazyImageObserver) {
      lazyImageResolvers.set(element, resolver);
      lazyImageObserver.observe(element);
    } else {
      loadImage(element, img, webp).then(resolver);
    }
  });

  setContext('lazy-image-loader', { loadImage: load });
</script>

<slot />

export const ie11 = !!window.MSInputMethodContext && !!document.documentMode;

export const loadImage = (el, src, webp) =>
  new Promise((resolve, reject) => {
    const load = (extension) => () => {
      const img = new Image();
      const url = `${window.location.origin}${src}.${extension}`;

      img.onload = () => {
        el.style.backgroundImage = `url(${url})`;
        resolve();
      };

      img.onerror = () => {
        reject(new Error(`Could not load ${src}`));
      };

      img.src = url;
    };

    if (webp) supportsWebp.then(load('webp')).catch(load('jpg'));
    else load('jpg')();
  });

export const testWebpSupport = (feature) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    const features = {
      basic:
        'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=',
      alpha:
        'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAABBxAR/Q9ERP8DAABWUDggGAAAADABAJ0BKgEAAQADADQlpAADcAD++/1QAA==',
      animation:
        'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
      lossless:
        'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
    };

    img.onload = function onload() {
      // the images should have these dimensions
      if (this.width === 1) {
        resolve();
      } else {
        reject(new Error('No WebP support'));
      }
    };

    img.onerror = () => {
      reject(new Error('No WebP support'));
    };

    img.src = features[feature] || features.basic;
  });

export const supportsWebp = testWebpSupport('alpha');

export const scrollTo = (element) => {
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY,
  });
};

export const timeout = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getTextWidth = async (text, fontFamily, otherStyles) => {
  const element = document.createElement('div');

  element.textContent = text;

  element.style.position = 'absolute';
  element.style.float = 'left';
  element.style.whiteSpace = 'nowrap';
  element.style.visibility = 'hidden';
  element.style.font = `1rem ${fontFamily || 'sans-serif'}`;

  Object.keys(otherStyles).forEach((style) => {
    element.style[style] = otherStyles[style];
  });

  document.body.appendChild(element);

  // Wait one event loop to let the browser render the element
  await timeout(0);

  const width = element.getBoundingClientRect().width;

  element.remove();

  return width;
};

export const getAppliedStyle = (element, property) => {
  const styles = {};
  let camel;
  let prop;
  let style;
  let value;

  if (window.getComputedStyle) {
    style = window.getComputedStyle(element, null);
    for (let i = 0, l = style.length; i < l; i++) {
      prop = style[i];
      camel = prop.replace(/-([a-z])/g, (a, b) => b.toUpperCase());
      if (property) {
        if (property === camel) {
          return style[camel];
        }
      } else {
        value = style.getPropertyValue(prop);
        styles[camel] = value;
      }
    }
    return styles;
  }

  style = element.currentStyle;
  if (style) {
    for (prop in style) {
      if (property) {
        if (property === prop) {
          return style[prop];
        }
      } else {
        styles[prop] = style[prop];
      }
    }
    return styles;
  }

  return property;
};

export const fade = (
  node,
  { delay = 0, duration = 200, easing = (x) => x }
) => {
  const o = +getComputedStyle(node).opacity;

  return { delay, duration, css: (t) => `opacity: ${easing(t) * o}` };
};

export const fadeScale = (
  node,
  { delay = 0, duration = 200, easing = (x) => x, baseScale = 0 }
) => {
  const o = +getComputedStyle(node).opacity;
  const m = getComputedStyle(node).transform.match(/scale\(([0-9.]+)\)/);
  const s = m ? m[1] : 1;
  const is = 1 - baseScale;

  return {
    delay,
    duration,
    css: (t) => {
      const eased = easing(t);
      return `opacity: ${eased * o}; transform: scale(${
        eased * s * is + baseScale
      })`;
    },
  };
};

// Adaption of https://github.com/ORCID/bibtexParseJs/blob/master/bibtexParse.js
// toBibtex
export const toBibTex = (obj, ignore = []) => {
  const entrySep = ',\n';
  const indent = '  ';
  let out = '';

  out += '@' + obj.entryType;
  out += '{';
  if (obj.citationKey) out += obj.citationKey + entrySep;
  if (obj.entry) out += obj.entry;
  if (obj.entryTags) {
    let tags = indent;
    Object.keys(obj.entryTags)
      .filter((tag) => ignore.indexOf(tag.toLowerCase()) === -1)
      .forEach((tag) => {
        if (tags.trim().length !== 0) tags += entrySep + indent;
        const value = Array.isArray(obj.entryTags[tag])
          ? obj.entryTags[tag].join(' and ')
          : obj.entryTags[tag];
        tags += `${tag} = {${value}}`;
      });
    out += tags;
  }
  out += '\n}';

  return out;
};

export function scrollToHash(event) {
  event.preventDefault();
  window.history.replaceState({}, '', this.href);
  scrollTo(document.querySelector(event.srcElement.hash));
}

export function mdLinksToHtml(text) {
  if (!text) return text;
  const matches = [...text.matchAll(/(\[([^[]+)\]\(([^(]+)\).*?)+/gm)];
  let offset = 0;
  if (!matches.length) return text;
  return matches.reduce((_html, match) => {
    const pre = _html.substring(0, match.index + offset);
    const link = `<a href="${match[3]}" target="_blank" rel="noopener noreferrer">${match[2]}</a>`;
    const post = _html.substring(match.index + match[0].length + offset);
    offset = link.length - match[0].length;
    return `${pre}${link}${post}`;
  }, text);
}

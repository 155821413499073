<script>
  import { getContext } from 'svelte';

  import Background from './Background.svelte';
  import Code from './Code.svelte';
  import Iframe from './Iframe.svelte';
  import LinkList from './LinkList.svelte';

  import { toBibTex } from './utils';

  export let publications;
  export let theses = false;

  $: thumbnail = !theses;

  const { open: openModal } = getContext('simple-modal');
  const ie11 = !!window.MSInputMethodContext && !!document.documentMode;
  const identity = x => x;
  const links = [
    { prop: 'eprint', name: 'Paper', icon: 'document', map: identity },
    { prop: 'projectPage', name: 'Website', icon: 'globe', map: identity },
    { prop: 'code', name: 'Code', icon: 'code', map: identity },
    { prop: 'data', name: 'Data', icon: 'data', map: identity },
    { prop: 'video', name: 'Video', icon: 'video', notIf: ['youtube'], map: identity },
    { prop: 'youtube', name: 'Video', icon: 'video', button: true, map: identity },
    {
      prop: 'presentation',
      name: 'Presentation',
      icon: 'presentation',
      notIf: ['speakerdeck', 'presentationYoutube'],
      map: identity
    },
    {
      prop: 'speakerdeck',
      name: 'Presentation',
      icon: 'presentation',
      button: true,
      map: identity
    },
    {
      prop: 'presentationYoutube',
      name: 'Presentation',
      icon: 'presentation',
      button: true,
      map: identity
    },
    {
      prop: 'doi',
      name: 'doi',
      icon: 'doi',
      map: doi => `https://doi.org/${doi}`
    },
  ];
  const ignore = [
    'abstract',
    'cofirstauthors',
    'authornote',
    'eprint',
    'projectpage',
    'code',
    'data',
    'video',
    'youtube',
    'youtubeaspectratio',
    'youtubebg',
    'presentation',
    'presentationyoutube',
    'presentationyoutubeaspectratio',
    'presentationyoutubebg',
    'presentationyoutubestarttime',
    'speakerdeck',
    'speakerdeckaspectratio',
    'speakerdeckbg',
    'award',
    'awardurl',
    'remark',
    'remarkurl',
  ];

  const showBib = (pub) => () => {
    // Clear selections
    window.getSelection().removeAllRanges();
    openModal(Code, { code: toBibTex(pub, ignore), smaller: true });
  };

  const styleDarkModel = { background: 'black' };

  const showLink = (pub, link) => () => {
    if (link.prop.toLowerCase().indexOf('youtube') >= 0) {
      const id = pub[link.prop];
      const aspectRatio = pub[`${link.prop}AspectRatio`];
      const bg = pub[`${link.prop}Bg`];
      const startTime = pub[`${link.prop}StartTime`];
      const t = startTime ? `&t=${startTime}` : '';
      openModal(
        Iframe,
        {
          src: `https://www.youtube.com/embed/${id}?showinfo=0&rel=0${t}`,
          title: `Video Introduction to "${pub.title}"`,
          aspectRatio: aspectRatio || 0.6
        },
        {
          styleWindow: bg === 'black' ? styleDarkModel : {}
        }
      );
    }
    if (link.prop.toLowerCase().indexOf('speakerdeck') >= 0) {
      openModal(
        Iframe,
        {
          src: `https://speakerdeck.com/player/${pub.speakerdeck}`,
          title: `Presentation Slides for "${pub.title}"`,
          aspectRatio: pub.speakerdeckAspectRatio || 0.6
        },
        {
          styleWindow: pub.speakerdeckBg === 'black' ? styleDarkModel : {}
        }
      );
    }
  };
</script>

<style lang="scss">
  @import 'globals';

  $color: #202080;
  $background: #e3abff;

  ol {
    margin: 0;
    padding: 0;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0.5em 0 0.25em 0;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  .publications > li {
    list-style: none;
    margin-top: 2rem;
  }

  .publications > li:first-child {
    margin-top: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    .wrapper {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;
      grid-template-columns: auto;
    }
  }

  .publications.ie11 .thumbnail,
  .publications.ie11 .year > time {
    display: block;
    width: 6rem;
    margin-right: 1rem;
  }

  .publications.ie11 .body {
    width: calc(100% - 7rem);
  }

  .publications > li:before {
    display: block;
    content: " ";
    margin-top: -4rem;
    height: 4rem;
    visibility: hidden;
  }

  .thumbnail {
    position: relative;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .thumbnail:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66%;
  }

  .body {
    position: relative;
  }

  .authors {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    line-height: 1.25em;
  }

  .authors li {
    display: flex;
    list-style: none;
  }

  .authors li:after {
    content: ',';
    padding-right: 0.25em;
  }

  .authors li:last-child:after {
    content: '';
  }

  .authors li:last-child:before {
    content: 'and';
    padding-right: 0.25em;
  }

  .authors li:first-child:last-child:before {
    content: '';
    padding-right: 0;
  }

  .coFirstAuthor {
    display: flex;
  }

  strong.coFirstAuthor:after,
  span.coFirstAuthor:after {
    content: '*';
  }

  ol.coFirstAuthor:after {
    content: '(* Joint first authors)';
    padding-left: 0.25em;
    font-style: italic;
  }

  .journal > *:after {
    content: ', ';
  }

  .journal > *:last-child:after {
    content: '.';
  }

  .award > * {
    border-radius: 1em;
    color: var(--award-color);
    background: var(--color-033);
  }

  .award > a:hover {
    color: var(--color);
    background: var(--award-color);
  }

  .year > time {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    h4 {
      line-height: normal;
      margin: 0;
    }

    @supports (display: grid) {
      .wrapper {
        grid-template-columns: 6rem auto;
      }
    }

    .authors {
      line-height: normal;
    }

    .thumbnail:before {
      padding-top: 100%;
    }
  }
</style>

<ol class="publications" class:ie11>
  {#each publications as pub, index}
    <li id={pub.citationKey}>
      <div class="wrapper">
        {#if thumbnail}
          <div>
            <div class="thumbnail">
              <Background
                img="/assets/images/thumbnail-{pub.citationKey}"
                size="contain"
                webp
              />
            </div>
          </div>
        {:else}
          <div class="year"><time>{pub.entryTags.year}</time></div>
        {/if}
        <div class="body">
          {#if !theses}
            <ol
              class="authors"
              class:coFirstAuthor={pub.entryTags.coFirstAuthors}
            >
              {#each pub.entryTags.author as author, j}
                {#if author === 'Fritz Lekschas'}
                  <li>
                    <strong
                      class:coFirstAuthor={pub.entryTags.coFirstAuthors && j < pub.entryTags.coFirstAuthors}

                    >
                      Fritz Lekschas
                      {#if pub.entryTags.authorNote && +pub.entryTags.authorNote.substring(0, pub.entryTags.authorNote.indexOf(':')) === j}
                        <em>({@html pub.entryTags.authorNote.substring(pub.entryTags.authorNote.indexOf(':') + 1)})</em>
                      {/if}
                    </strong>
                  </li>
                {:else}
                  <li>
                    <span
                      class:coFirstAuthor={pub.entryTags.coFirstAuthors && j < pub.entryTags.coFirstAuthors}
                    >
                      {author}
                      {#if pub.entryTags.authorNote && +pub.entryTags.authorNote.substring(0, pub.entryTags.authorNote.indexOf(':')) === j}
                        <em>({@html pub.entryTags.authorNote.substring(pub.entryTags.authorNote.indexOf(':') + 1)})</em>
                      {/if}
                    </span>
                  </li>
                {/if}
              {/each}
            </ol>

          {/if}
          <h4>{pub.entryTags.title}</h4>
          <div class="journal">
            {#if theses}
              <em>{pub.entryTags.type}</em>
              <span>{pub.entryTags.school}</span>
            {:else if pub.entryTags.journal}
              <em>{pub.entryTags.journal}{#if pub.entryTags.series}&nbsp;({pub.entryTags.series}){/if}</em>
            {:else if pub.entryTags.booktitle}
              <em>{pub.entryTags.booktitle}{#if pub.entryTags.series}&nbsp;({pub.entryTags.series}){/if}</em>
            {/if}
            {#if thumbnail}
              <span>{pub.entryTags.year}</span>
            {/if}
          </div>
          {#if pub.entryTags.award || pub.entryTags.remark}
            <LinkList bigger>
              {#if pub.entryTags.award}
                {#each pub.entryTags.award.split(',') as award, i}
                  <li class="award">
                    {#if pub.entryTags.awardUrl && pub.entryTags.awardUrl.split(',')[i]}
                      <a
                        href={pub.entryTags.awardUrl.split(',')[i]}
                        target={pub.entryTags.awardUrl.split(',')[i].startsWith('http') ? '_blank' : ''}
                        rel={pub.entryTags.awardUrl.split(',')[i].startsWith('http') ? 'noopener noreferrer' : ''}
                      >
                        <svg>
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/assets/images/icons.svg#trophy" />
                        </svg>
                        <span class="text">{award}</span>
                      </a>
                    {:else}
                      <span>
                        <svg>
                          <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="/assets/images/icons.svg#trophy" />
                        </svg>
                        <span class="text">{award}</span>
                      </span>
                    {/if}
                  </li>
                {/each}
              {/if}
              {#if pub.entryTags.remark}
                <li class="remark">
                  {#if pub.entryTags.remarkUrl}
                    <a
                      href={pub.entryTags.remarkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#badge" />
                      </svg>
                      <span class="text">{pub.entryTags.remark}</span>
                    </a>
                  {:else}
                    <span>
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#badge" />
                      </svg>
                      <span class="text">{pub.entryTags.remark}</span>
                    </span>
                  {/if}
                </li>
              {/if}
            </LinkList>
          {/if}
          <LinkList>
            {#each links as link}
              {#if pub.entryTags[link.prop] && !(link.notIf && link.notIf.some(notIf => pub.entryTags[notIf]))}
                {#if link.button}
                  <li>
                    <button on:click={showLink(pub.entryTags, link)}>
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="assets/images/icons.svg#{link.icon}" />
                      </svg>
                      <span class="text">{link.name}</span>
                    </button>
                  </li>
                {:else}
                  <li>
                    <a
                      href={link.map(pub.entryTags[link.prop])}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="assets/images/icons.svg#{link.icon}" />
                      </svg>
                      <span class="text">{link.name}</span>
                    </a>
                  </li>
                {/if}
              {/if}
            {/each}
            <li>
              <button on:click={showBib(pub)}>
                <svg>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="assets/images/icons.svg#quote" />
                </svg>
                <span class="text">Cite</span>
              </button>
            </li>
          </LinkList>
        </div>
      </div>
    </li>
  {/each}
</ol>

<script>
  import { onMount } from 'svelte';
  import { cubicInOut } from 'svelte/easing';
  import Modal from 'svelte-simple-modal';

  import LazyImageLoader from './LazyImageLoader.svelte';
  import Nav from './Nav.svelte';
  import Home from './Home.svelte';
  import Bio from './Bio.svelte';
  import Research from './Research.svelte';
  import Contact from './Contact.svelte';
  import { fade, fadeScale, scrollTo } from './utils.js';

  let y = 0;
  let windowHeight = 0;
  let homeHeight = 0;
  let homeOffsetTop = 0;
  let bioHeight = 0;
  let bioOffsetTop = 0;
  let researchHeight = 0;
  let researchOffsetTop = 0;
  let contactHeight = 0;
  let contactOffsetTop = 0;

  $: winHeightThird = windowHeight * 0.333;
  $: isHome = y <= homeHeight - winHeightThird;
  $: isBio = (
    y > homeHeight - winHeightThird &&
    y <= bioOffsetTop + bioHeight - winHeightThird
  );
  $: isResearch = (
    y > bioOffsetTop + bioHeight - winHeightThird &&
    y <= researchOffsetTop + researchHeight - winHeightThird
  );
  $: isContact = y > researchOffsetTop + researchHeight - winHeightThird;
  $: isContactFully = y >= contactOffsetTop;

  $: if (isContact) {
    document.body.classList.add('background-contact');
  } else {
    document.body.classList.remove('background-contact');
  }

  onMount(() => {
    const anchor = window.location.hash.substr(1);
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) scrollTo(anchorEl);
    }
  });
</script>

<svelte:options immutable/>
<svelte:window bind:scrollY={y} bind:innerHeight={windowHeight}/>

<LazyImageLoader>
  <Modal
    closeButton={false}
    transitionBg={fade}
    transitionBgProps={{ easing: cubicInOut }}
    transitionWindow={fadeScale}
    transitionWindowProps={{ easing: cubicInOut, baseScale: 0.5 }}
  >
    <Nav {isHome} {isBio} {isResearch} {isContact}/>
    <Home
      bind:height={homeHeight}
      bind:offsetTop={homeOffsetTop}
    />
    <Bio
      bind:height={bioHeight}
      bind:offsetTop={bioOffsetTop}
    />
    <Research
      bind:height={researchHeight}
      bind:offsetTop={researchOffsetTop}
    />
    <Contact
      bind:height={contactHeight}
      bind:offsetTop={contactOffsetTop}
      active={isContactFully}
    />
  </Modal>
</LazyImageLoader>

<script>
  import { getContext } from 'svelte';
  import bibtexParseJs from 'bibtex-parse-js';

  import preprintsBib from './refs/preprints.bib';
  import publicationsBib from './refs/publications.bib';
  import thesesBib from './refs/theses.bib';
  import service from './content/service.yaml';
  import talks from './content/talks.yaml';
  import teaching from './content/teaching.yaml';

  import CenterHeadline from './CenterHeadline.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import Iframe from './Iframe.svelte';
  import LinkList from './LinkList.svelte';
  import NavBg from './NavBg.svelte';
  import PubList from './PubList.svelte';
  import Section from './Section.svelte';
  import ServiceList from './ServiceList.svelte';
  import TalkList from './TalkList.svelte';
  import TeachingList from './TeachingList.svelte';
  import Wrap from './Wrap.svelte';
  import VSpace from './VSpace.svelte';

  import { ie11, scrollToHash } from './utils.js'

  export let height;
  export let offsetTop;

  const parseToNum = {
    year: true,
    month: true,
    day: true
  }

  const parseAuthors = bibStr => bibStr
    .replace(/\r?\n|\r/g, ' ')
    .split(' and ')
    .map(author => author
      .split(',')
      .map(name => name.trim())
      .reverse()
      .join(' ')
      .replace('\\"u', 'ü')
      .replace('\\textquoteright', '’')
    );

  const parseDTypes = (bib) => {
    Object.keys(bib.entryTags).forEach((key) => {
      if (parseToNum[key]) {
        bib.entryTags[key] = +bib.entryTags[key];
      } else {
        // Replace curly braces
        bib.entryTags[key] = bib.entryTags[key].replace(/[{}]/g, '');

        switch (key) {
          case 'author':
            bib.entryTags[key] = parseAuthors(bib.entryTags[key]);
            break;
          default:
            // Nothing
            break;
        }
      }
    });

    return bib;
  }

  const sortByDate = (refA, refB) => {
    if (refA.entryTags.year > refB.entryTags.year) return -1;
    if (refA.entryTags.year < refB.entryTags.year) return 1;

    if (refA.entryTags.month > refB.entryTags.month) return -1;
    if (refA.entryTags.month < refB.entryTags.month) return 1;

    if (refA.entryTags.day > refB.entryTags.day) return -1;
    if (refA.entryTags.day < refB.entryTags.day) return 1;

    return 0;
  };

  const preprints = bibtexParseJs
    .toJSON(preprintsBib)
    .map(parseDTypes)
    .sort(sortByDate);
  const publications = bibtexParseJs
    .toJSON(publicationsBib)
    .map(parseDTypes)
    .sort(sortByDate);
  const theses = bibtexParseJs
    .toJSON(thesesBib)
    .map(parseDTypes)
    .sort(sortByDate);

  const background = '#e3abff';
</script>

<style lang="scss">
  @import 'globals';

  ol, ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
  }

  p > a {
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    border-radius: 1em;
    text-decoration: underline solid var(--color-033);
    transition: color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
                background 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  p > a:hover,
  p > a:focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: var(--color);
  }

  .projects li {
    margin-top: 2rem;
  }

  .projects li:first-child {
    margin-top: 0;
  }

  .labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.25rem;
    font-size: 0.9em;
  }

  .labels h5 {
    display: inline;
    font-size: inherit;
    margin: 0;
  }

  .label-list li {
    display: inline;
    margin-right: 0.25rem;
    list-style: none;
    line-height: 2em;
  }

  .label-list li:last-child {
    margin-right: 0;
  }

  .label-list a {
    padding: 0 0.5em;
    text-decoration: none;
    border-radius: 1em;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 1px var(--color-033);
    transition: color $normal $ease,
                background $normal $ease,
                box-shadow $normal $ease;
  }

  .label-list a:hover,
  .label-list a:active,
  .label-list a:focus {
    outline: none;
    color: white;
    background: var(--color);
    box-shadow: 0 0 0 1px var(--color);
  }

  /* 480px */
  @media screen and (min-width: 30em) {
    .intro {
      font-size: 1.125em;
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    .intro, .projects p {
      text-align: justify;
    }

    .intro {
      font-size: 1.25em;
    }
  }
</style>

<Section
  id="research"
  background={background}
  bind:height={height}
  bind:offsetTop={offsetTop}
>
  <NavBg background="rgba(227, 171, 255, 0.9)" />
  <Wrap>
    <FullWidthHeadline title="The Scientist In Me"/>
    <p class="intro">Fritz develops scalable visual exploration systems for analyzing increasingly-large and complex biomedical datasets. He uses visualization as the primary means of interacting with data and human-centered AI methods to guide decision making at scale. His goal is to build new techniques that enable scientists to interactively identify, explore, and compare patterns in their data efficiently.</p>

    <CenterHeadline id="projects" title="Projects"/>
    <ol class="projects">
      <li>
        <h4>4D Nucleome</h4>
        <p>The <a href="https://www.4dnucleome.org" target="_blank" rel="noopener noreferrer">4D Nucleome project</a> is a large-scale <abbr title="National Institute of Health">NIH</abbr>-funded effort to understand the principles underlying nuclear organization in space and time, the role nuclear organization plays in gene expression and cellular function, and how changes in nuclear organization affect normal development as well as various diseases. As part of the <a href="http://dcic.4dnucleome.org" target="_blank" rel="noopener noreferrer">Data Coordination and Integration Center</a>, we are developing visualization methods and tools for exploring and analyzing the datasets generated throughout the program.</p>
        <div class="labels">
          <ul class="label-list">
            <li>
              <h5>Publications:</h5>
            </li>
            <li>
              <a
                href="#lekschas2020peax"
                on:click={scrollToHash}
              >Peax</a>
            </li>
            <li>
              <a
                href="#lekschas2020pattern"
                on:click={scrollToHash}
              >Scalable Insets</a>
            </li>
            <li>
              <a
                href="#kerpedjiev2018higlass"
                on:click={scrollToHash}
              >HiGlass</a>
            </li>
            <li>
              <a
                href="#lekschas2018hipiler"
                on:click={scrollToHash}
              >HiPiler</a>
            </li>
            <li>
              <a
                href="#dekker20174d"
                on:click={scrollToHash}
              >The 4D nucleome project</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <h4>Connectomics</h4>
        <p><a href="https://vcg.seas.harvard.edu/projects#connectomics" target="_blank" rel="noopener noreferrer">Connectomics</a> is the production and study of connectomes: comprehensive maps of connections within the mouse brain or eye. This project focuses on the segmentation, visualization and analysis of brain scans in electron and optical microscopy in the multi-terabyte range.</p>
        <div class="labels">
          <ul class="label-list">
            <li>
              <h5>Publications:</h5>
            </li>
            <li>
              <a
                href="#matejek2017compresso"
                on:click={scrollToHash}
              >Compresso</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <h4>Refinery Platform</h4>
        <p>The <a href="http://www.refinery-platform.org" target="_blank" rel="noopener noreferrer">Refinery Platform</a> is a web-based system for data management, data visualization, and analysis of biomedical data sets powered by an ISA-Tab-compatible data repository. Analyses are implemented as Galaxy workflows and executed through the Galaxy API.</p>
        <div class="labels">
          <ul class="label-list">
            <li>
              <h5>Publications:</h5>
            </li>
            <li>
              <a
                href="#lekschas2017satori"
                on:click={scrollToHash}
              >SATORI</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <h4>Human Pluripotent Stem Cell Registry</h4>
        <p>The <a href="http://hpscreg.eu" target="_blank" rel="noopener noreferrer">Human Pluripotent Stem Cell Registry (<abbr title="Human Pluripotent Stem Cell Registry">hPSCreg</abbr>)</a> was created to offer the research community, legislators, regulators and the general public at large an in-depth overview on the current status of human pluripotent stem cell research in Europe.</p>
        <div class="labels">
          <ul class="label-list">
            <li>
              <h5>Publications:</h5>
            </li>
            <li>
              <a
                href="#seltmann2015hpscreg"
                on:click={scrollToHash}
              >hPSCreg</a>
            </li>
            <li>
              <a
                href="#kurtz2018standard"
                on:click={scrollToHash}
              >A Standard Nomenclature for hPSC</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <h4>CellFinder</h4>
        <p><a href="http://cellfinder.org" target="_blank" rel="noopener noreferrer">CellFinder</a> maps validated gene and protein expression, phenotype and images related to cell types. The data allow characterization and comparison of cell types and can be browsed by using the body browser and by searching for cells or genes. All cells are related to more complex systems such as tissues, organs and organisms and arranged according to their position in development. CellFinder incorporates complex data exploration tools using visualization and analysis tools.</p>
        <div class="labels">
          <ul class="label-list">
            <li>
              <h5>Publications:</h5>
            </li>
            <li>
              <a
                href="#lekschas2014semantic"
                on:click={scrollToHash}
              >Semantic Body Browser</a>
            </li>
            <li>
              <a
                href="#stachelscheid2014cellfinder"
                on:click={scrollToHash}
              >Cellfinder</a>
            </li>
            <li>
              <a
                href="#seltmann2013celda"
                on:click={scrollToHash}
              >CELDA</a>
            </li>
            <li>
              <a
                href="#neves2013preliminary"
                on:click={scrollToHash}
              >Textmining</a>
            </li>
            <li>
              <a
                href="#amrani2015mgfm"
                on:click={scrollToHash}
              >MFGM</a>
            </li>
          </ul>
        </div>
      </li>
    </ol>

    <CenterHeadline id="publications" title="Publications"/>
    <PubList publications={publications}/>

    {#if preprints.length}
      <CenterHeadline id="preprints" title="Preprints" secondary/>
      <PubList publications={preprints}/>
    {/if}

    <CenterHeadline id="theses" title="Theses"/>
    <PubList publications={theses} theses/>

    <CenterHeadline id="presentations" title="Presentations"/>
    <TalkList talks={talks}/>

    <CenterHeadline id="teaching" title="Teaching"/>
    <TeachingList teaching={teaching}/>

    <CenterHeadline id="service" title="Service"/>
    <ServiceList service={service} />

    <VSpace height="4.5"/>
  </Wrap>
</Section>

<script>
  export let id;
  export let abs = false;
</script>

<style>
  .anchor {
    top: -1rem;
  }

  .abs {
    position: absolute;
  }
</style>

<div {id} class="anchor" class:abs />

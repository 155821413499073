<script>
  export let title;
  export let id = '';
  export let secondary = false;

  const showAnchor = id && id.length;
</script>

<style>
  h3,
  h4 {
    display: flex;
    align-items: center;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 900;
    margin: -1rem 0 2rem 0;
    padding-top: 3rem;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: -2rem 0 1rem 0;
    padding-top: 3rem;
  }

  .container {
    position: relative;
  }

  .anchor {
    display: block;
    position: absolute;
    left: 0;
    min-width: 1.2em;
    text-align: center;
    margin: 0.2em;
    line-height: 1.2em;
    opacity: 0;
    box-shadow: none;
    border-radius: 1em;
    text-decoration: none;
    transition: opacity var(--tfast) var(--ease), color var(--tfast) var(--ease),
      background var(--tfast) var(--ease);
  }

  .showAnchor .anchor:hover,
  .showAnchor:target .anchor {
    color: white;
    text-decoration: none;
    background: var(--color);
  }

  .showAnchor:hover .anchor,
  .showAnchor:target .anchor {
    opacity: 1;
  }

  .text {
    transition: padding-left var(--tfast) var(--ease);
  }

  .showAnchor:hover .text,
  .showAnchor:target .text {
    padding-left: 1.5em;
  }

  .lines {
    display: flex;
    align-items: center;
  }

  .line-right {
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    margin-left: 0.5rem;
    background: var(--color-033);
  }

  .line-left {
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    margin-right: 0.5rem;
    background: var(--color-033);
  }

  .lines.dashed .line-left,
  .lines.dashed .line-right {
    height: 0;
    border-bottom: 1px dashed var(--color-033);
    background: transparent;
  }

  /* 480px */
  @media screen and (min-width: 30em) {
    .line-left {
      margin-left: 1.5rem;
      margin-right: 0.75rem;
    }

    .line-right {
      margin-left: 0.75rem;
      margin-right: 1.5rem;
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    h3 {
      font-size: 2rem;
      margin-bottom: 3rem;
      padding-top: 4rem;
    }

    h4 {
      font-size: 1.375rem;
      margin-bottom: 2rem;
      padding-top: 4rem;
    }

    .lines:before {
      margin-left: 2rem;
      margin-right: 1rem;
    }

    .lines:after {
      margin-left: 1rem;
      margin-right: 2rem;
    }
  }

  /* 960px */
  @media screen and (min-width: 60em) {
    .lines:before {
      margin-left: 3rem;
      margin-right: 1.5rem;
    }

    .lines:after {
      margin-left: 1.5rem;
      margin-right: 3rem;
    }
  }
</style>

{#if secondary}
  <h4 class="lines dashed" {id} class:showAnchor>
    <span class="line-left"/>
    <span class="container">
      {#if id}<a href={`#${id}`} class="anchor">#</a>{/if}
      <span class="text">{title}</span>
    </span>
    <span class="line-right"/>
  </h4>
{:else}
  <h3 class="lines" {id} class:showAnchor>
    <span class="line-left"/>
    <span class="container">
      {#if id}<a href={`#${id}`} class="anchor">#</a>{/if}
      <span class="text">{title}</span>
    </span>
    <span class="line-right"/>
  </h3>
{/if}

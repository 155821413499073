<script>
  import Wrap from './Wrap.svelte';

  import { scrollToHash } from './utils.js';

  export let isHome = false;
  export let isBio = false;
  export let isResearch = false;
  export let isContact = false;
</script>

<style lang="scss">
  @import 'globals';

  header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 2.5rem;
    background: rgba(32, 32, 128, 0.1);
  }

  @supports (backdrop-filter: blur(1rem) brightness(85%)) {
    header {
      background: none;
      -webkit-backdrop-filter: blur(1rem) brightness(85%);
      backdrop-filter: blur(1rem) brightness(85%);
    }
  }

  nav {
    display: flex;
    justify-content: center;
  }

  a {
    position: relative;
    display: block;
    height: 2.5rem;
    flex-grow: 1;
    font-size: 1rem;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: 0;
  }

  a:hover,
  a:focus,
  a.active {
    font-weight: bold;
  }

  a:focus {
    outline: none;
  }

  a > span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2.5rem;
    background: rgba(32, 32, 128, 0);
    will-change: background, font-weight;
    transition: background $normal $ease, font-weight $normal $ease;
    pointer-events: none;
  }

  a > span:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--color);
    transform: scale(0, 1);
    will-change: transform;
    transition: transform $normal $ease;
  }

  a:hover > span:before,
  a:focus > span:before,
  a.active > span:before {
    outline: none;
    transform: scale(1, 1);
  }

  a.active {
    color: white;
    font-weight: bold;
    background: rgba(32, 32, 128, 1);
  }
</style>

<header>
  <Wrap>
    <nav class="flex-c flex-jc-c">
      <a
        class="bio"
        class:active={isBio}
        href="#bio"
        on:click={scrollToHash}
      >
        <span>Bio</span>
      </a>
      <a
        class="research"
        class:active={isResearch}
        href="#research"
        on:click={scrollToHash}
      >
        <span>Research</span>
      </a>
      <a
        class="contact"
        class:active={isContact}
        href="#contact"
        on:click={scrollToHash}
      >
        <span>Contact</span>
      </a>
    </nav>
  </Wrap>
</header>

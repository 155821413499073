<script>
  export let ratio = null;

  let ratioStyle = '';
  $: if (ratio) {
    const [width, height] = ratio.split(':');
    const relHeight = height / width * 100;
    ratioStyle = `padding-top: ${relHeight}%;`;
  }
</script>

<style>
  div {
    position: relative;
  }
</style>

<div>
  <div class="ratio" style={ratioStyle}></div>
  <slot />
</div>

<style>
  .wrap {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .wrap :global(.fullscreen) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  /* 480px */
  @media screen and (min-width: 30em) {
    .wrap :global(.fullscreen) {
      margin-left: -2rem;
      margin-right: -2rem;
    }

    .wrap {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    .wrap :global(.fullscreen) {
      margin-left: -10%; /* 1/10 */
      margin-right: -10%;
    }

    .wrap {
      margin-left: 8.3334%; /* 1/12 */
      margin-right: 8.3334%;
    }
  }

  /* 800px */
  @media screen and (min-width: 50em) {
    .wrap :global(.fullscreen) {
      margin-left: -16.6667%; /* 1/6 */
      margin-right: -16.6667%;
    }

    .wrap {
      margin-left: 12.5%; /* 1/8 */
      margin-right: 12.5%;
    }
  }

  /* 960px */
  @media screen and (min-width: 60em) {
    .wrap :global(.fullscreen) {
      margin-left: -25%; /* 1/4 */
      margin-right: -25%;
    }

    .wrap {
      margin-left: 16.6667%; /* 1/6 */
      margin-right: 16.6667%;
    }
  }

  /* 1280px */
  @media screen and (min-width: 80em) {
    .wrap :global(.fullscreen) {
      margin-left: -33.3334%; /* 1/3 */
      margin-right: -33.3334%;
    }

    .wrap {
      margin-left: 20%; /* 1/5 */
      margin-right: 20%;
    }
  }

  /* 1600px */
  @media screen and (min-width: 100em) {
    .wrap {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>

<div class="wrap">
  <slot></slot>
</div>

<script>
  import { afterUpdate, onMount } from 'svelte';

  export let id;
  export let background = '#222';
  export let height = 0;
  export let offsetTop = 0;

  let ref;

  function setOffsetTop() {
    offsetTop = ref.offsetTop;
  }

  onMount(setOffsetTop);
  afterUpdate(setOffsetTop);
</script>

<style>
  .section {
    position: relative;
    min-height: 100vh;
  }
</style>

<svelte:window on:resize={setOffsetTop}/>

<section
  {id}
  class="section"
  style="background: {background}"
  bind:this={ref}
  bind:clientHeight={height}
>
  <slot></slot>
</section>

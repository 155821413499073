<script>
  export let direction = 'down';
  export let size = 10;
  export let cutoutSize = null;
  export let cutoutColor = 'white';

  $: up = direction === 'up';
  $: down = direction === 'down';
  $: left = direction === 'left';
  $: right = direction === 'right';

  $: _cutoutSize = cutoutSize || Math.ceil(size / 2);

  let mainStyle = '';
  let cutoutStyle = '';
  $: if (_cutoutSize || cutoutColor) {
    switch (direction) {
      case 'up':
        mainStyle = `border-width: ${size}px; border-top-width: 0;`;
        cutoutStyle = `border-width: ${_cutoutSize}px; border-top-width: 0; border-left-color: ${cutoutColor}; `;
        break;

      case 'left':
        mainStyle = `border-width: ${size}px; border-left-width: 0;`;
        cutoutStyle = `border-width: ${_cutoutSize}px; border-left-width: 0; border-bottom-color: ${cutoutColor}; `;
        break;

      case 'right':
        mainStyle = `border-width: ${size}px; border-right-width: 0;`;
        cutoutStyle = `border-width: ${_cutoutSize}px; border-right-width: 0; border-right-color: ${cutoutColor}; `;
        break;

      case 'down':
      default:
        mainStyle = `border-width: ${size}px; border-bottom-width: 0;`;
        cutoutStyle = `border-width: ${_cutoutSize}px; border-bottom-width: 0; border-top-color: ${cutoutColor}; `;
        break;
    }
    cutoutStyle += `transform: translate(-50%, -${size}px);`;
  }
</script>

<style>
  .main {
    border-color: var(--color);
  }

  .cutout {
    position: absolute;
    z-index: 1;
    border-color: white;
  }

  .up {
    width: 0;
    height: 0;
    border-left-color: transparent;
    border-left-style: solid;
    border-right-color: transparent;
    border-right-style: solid;
    border-bottom-style: solid;
  }

  .down {
    width: 0;
    height: 0;
    border-left-color: transparent;
    border-left-style: solid;
    border-right-color: transparent;
    border-right-style: solid;
    border-top-style: solid;
  }

  .right {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-top-style: solid;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-left-style: solid;
  }

  .left {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-top-style: solid;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-right-style: solid;
  }
</style>


<div class="main" class:up class:down class:left class:right style={mainStyle}>
  <div class="cutout" class:up class:down class:left class:right style={cutoutStyle} />
</div>

<script>
  import { onMount } from 'svelte';
  import stickybits from 'stickybits';

  export let background = 'black';
  export let transition = false;
  export let active = false;

  onMount(() => {
    stickybits('.sticky');
  });
</script>

<style lang="scss">
  @import 'globals';

  div {
    z-index: 9;
    height: 2.5rem;
  }

  @supports (backdrop-filter: blur(1rem) brightness(87%)) {
    div {
      visibility: hidden;
    }
  }

  .transition {
    opacity: 0;
    transition: opacity $normal $ease;
  }

  .active {
    opacity: 1;
  }
</style>

{#if transition}
  <div
    class="sticky transition"
    class:active
    style="background: {background}"
  />
{:else}
  <div class="sticky" style="background: {background}" />
{/if}

<script>
  export let aspectRatio = 0.6;
  export let src;
  export let title = '';
</script>

<style>
  div {
    position: relative;
  }

  span {
    display: block;
    padding-top: 60%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>

<div>
  <span style="padding-top: {aspectRatio * 100}%"></span>
  <iframe
    {src}
    {title}
    frameborder="0"
    scrolling="no"
    allow="fullscreen"
    allowfullscreen
  />
</div>

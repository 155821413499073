<script>
  import Arrow from './Arrow.svelte';
  import Background from './Background.svelte';
  import Container from './Container.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import NavBg from './NavBg.svelte';
  import Section from './Section.svelte';
  import Wrap from './Wrap.svelte';
  import { scrollTo } from './utils';

  export let height;
  export let offsetTop;

  const background = '#3df2ce';

  let scrollIndicatorHeight = -1;

  $: scrollIndicatorVisible = scrollIndicatorHeight > 96;

  function scrollToBio(event) {
    event.preventDefault();
    window.history.replaceState({}, '', '#bio');
    scrollTo(document.querySelector('#bio'));
  }
</script>

<style lang="scss">
  @import 'globals';

  h2 {
    position: relative;
    z-index: 1;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
  }

  #blurb {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    left: 0;
    right: 0;
    /*
      -2.5rem for the nav bar
      -4rem for the top offset
      -1.125rem * 1.6 * 3 (rows) for the h2
      -4rem bottom padding
     */
    height: calc(100vh - 15.9rem);
    height: calc(100vh - 6.5rem);
  }

  #blurb .headline {
    position: absolute;
    top: -4rem;
    left: 0;
    right: 0;
  }

  #face-border {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: var(--color-033);
  }

  #scroll-indicator {
    position: relative;
    flex-grow: 1;
  }

  #scroll-indicator > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    transition: opacity $normal $ease;
  }

  #scroll-indicator.visible > .content {
    opacity: 1;
  }

  #arrow {
    cursor: pointer;
    padding: 1rem 1rem 0.5rem 1rem;
    opacity: 0;
    animation: jumping 1s infinite alternate;
  }

  @keyframes jumping {
    0%   {
      opacity: 0;
      transform: translate(0, -25%);
    }
    100% {
      opacity: 0.33;
      transform: translate(0, 10%);
    }
  }

  /* 480px */
  @media screen and (min-width: 22.5em) {
    h2 {
      font-size: 1.25rem;
    }
    #blurb {
      /*
        -2.5rem for the nav bar
        -4rem for the top offset
        -1.25rem * 1.6 * 3 (rows) for the h2
        -4rem bottom padding
       */
      height: calc(100vh - 16.5rem);
      height: calc(100vh - 6.5rem);
    }
  }

  /* 480px */
  @media screen and (min-width: 30em) {
    h2 {
      font-size: 1.5rem;
    }
    #blurb {
      /*
        -2.5rem for the nav bar
        -4rem for the top offset
        -1.5rem * 1.6 * 3 (rows) for the h2
        -4rem bottom padding
       */
      height: calc(100vh - 17.7rem);
      height: calc(100vh - 6.5rem);
    }
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    h2 {
      font-size: 1.75rem;
    }
    #blurb {
      /*
        -2.5rem for the nav bar
        -4rem for the top offset
        -1.75rem * 1.6 * 3 (rows) for the h2
        -4rem bottom padding
       */
      height: calc(100vh - 18.9rem);
      height: calc(100vh - 6.5rem);
    }
  }

  /* 800px */
  @media screen and (min-width: 50em) {
    h2 {
      font-size: 2rem;
    }
    #blurb {
      /*
        -2.5rem for the nav bar
        -4rem for the top offset
        -2rem * 1.6 * 3 (rows) for the h2
        -4rem bottom padding
       */
      height: calc(100vh - 20.1rem);
      height: calc(100vh - 6.5rem);
    }
  }
</style>

<Section
  id="home"
  background={background}
  bind:height={height}
  bind:offsetTop={offsetTop}
>
  <NavBg background="rgba(61, 242, 206, 0.9)"/>
  <Wrap>
    <div id="blurb">
      <div class="headline">
        <FullWidthHeadline level={1} title="Fritz Lekschas"/>
      </div>
      <Container ratio="2048:1404">
        <Background img="/assets/images/me" size="98% auto" position="center bottom" webp/>
        <div id="face-border" />
      </Container>
      <h2>
        A computer scientist who develops and researches scalable data visualization systems for analyzing biomedical data interactively.
      </h2>
      <div id="scroll-indicator" class:visible={scrollIndicatorVisible}>
        <div class="content" bind:clientHeight={scrollIndicatorHeight}>
          <div id="arrow" on:click={scrollToBio}>
            <Arrow direction="down" size={10} cutoutSize={4} cutoutColor={background} />
          </div>
        </div>
      </div>
    </div>
  </Wrap>
</Section>

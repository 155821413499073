<script>
  import { getContext } from 'svelte';

  import Iframe from './Iframe.svelte';
  import LinkList from './LinkList.svelte';

  import { mdLinksToHtml } from './utils';

  export let talks = [];

  const { open: openModal } = getContext('simple-modal');
  const ie11 = !!window.MSInputMethodContext && !!document.documentMode;
  const sixteenToNine = .5625;
  const fourToThree = .75;

  const styleDarkModel = { background: 'black' };

  const showSpeakerdeck = (
    id,
    {
      aspectRatio = sixteenToNine,
      darkMode = false
    } = {}
  ) => () => {
    openModal(
      Iframe,
      { src: `https://speakerdeck.com/player/${id}`, aspectRatio },
      { styleWindow: darkMode ? styleDarkModel : {} }
    );
  };

  const showVimeo = (
    id,
    {
      aspectRatio = sixteenToNine,
      darkMode = false
    } = {}
  ) => () => {
    openModal(
      Iframe,
      {
        src: `https://player.vimeo.com/video/${id}?color=ffffff&title=0&byline=0&portrait=0`,
        aspectRatio
      },
      { styleWindow: darkMode ? styleDarkModel : {} }
    );
  };

  const showYoutube = (
    id,
    {
      aspectRatio = sixteenToNine,
      darkMode = false,
      startTime = 0
    } = {}
  ) => () => {
    const t = startTime ? `&t=${startTime}` : '';
    openModal(
      Iframe,
      {
        src: `https://www.youtube.com/embed/${id}?showinfo=0&rel=0${t}`,
        aspectRatio
      },
      { styleWindow: darkMode ? styleDarkModel : {} }
    );
  };

  const toAspectRatio = (aspectRatio) => {
    switch (aspectRatio) {
      case 'sixteenToNine':
        return sixteenToNine;

      case 'fourToThree':
        return fourToThree;

      default:
        return aspectRatio;
    }
  };

  const parseTalks = () => {
    const years = {};
    return talks.map((talk) => {
      const parsedTalk = { ...talk };
      if (!years[parsedTalk.year]) {
        parsedTalk.showYear = true;
        years[parsedTalk.year] = true;
      }
      parsedTalk.host = mdLinksToHtml(parsedTalk.host);
      return parsedTalk;
    });
  };

  let parsedTalks = [];
  $: parsedTalks = parseTalks(talks);
</script>

<style lang="scss">
  @import 'globals';

  ol {
    margin: 0;
    padding: 0;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25em;
    margin: 0.5em 0 0.25em 0;
  }

  .talks .body .text :global(a) {
    margin: 0 -0.25rem;
    padding: 0 0.25rem;
    border-radius: 1em;
    text-decoration: underline solid var(--color-033);
    transition: color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
                background 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .talks .body .text :global(a):hover,
  .talks .body .text :global(a):focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: var(--color);
  }

  .talks > li {
    list-style: none;
    margin-top: 0.75rem;
  }

  .talks > li:first-child {
    margin-top: 0;
  }

  .talks > li.v-space {
    margin-top: 2rem;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    .wrapper {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;
      grid-template-columns: auto;
    }
  }

  .talks > li:before {
    display: block;
    content: " ";
    margin-top: -4rem;
    height: 4rem;
    visibility: hidden;
  }

  .year {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .ie11 .year {
    display: block;
    width: 6rem;
    margin-right: 1rem;
  }

  .ie11 .body {
    min-width: 20rem;
    width: calc(100% - 7rem);
  }

  .body {
    position: relative;
  }

  .date:before {
    content: '(';
  }

  .date:after {
    content: ')';
  }

  .comma-after:after {
    content: ',';
  }

  /* 640px */
  @media screen and (min-width: 40em) {
    h4 {
      line-height: normal;
      margin: 0;
    }

    @supports (display: grid) {
      .wrapper {
        grid-template-columns: 6rem auto;
      }
    }
  }
</style>

<ol class="talks" class:ie11>
  {#each parsedTalks as talk}
    <li class:v-space={talk.showYear}>
      <div class="wrapper">
        <div class="year">
          {#if talk.showYear}
            {talk.year}
          {/if}
        </div>
        <div class="body">
          <h4>{talk.title}</h4>
          <p class="text">
            <em class="type comma-after">{talk.type}</em>
            <span class="host comma-after">{@html talk.host}</span>
            {#if talk.location}
              <span class="location comma-after">{talk.location}</span>
            {/if}
            <time class="date">{talk.month} {talk.year}</time>
          </p>
          {#if talk.links && talk.links.length}
            <LinkList lessTopSpacing>
              {#each talk.links as link}
                <li>
                  {#if link.speakerdeck}
                    <button on:click={showSpeakerdeck(
                      link.speakerdeck,
                      {
                        aspectRatio: toAspectRatio(link.aspectRatio),
                        darkMode: link.darkMode
                      }
                    )}>
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#presentation" />
                      </svg>
                      <span class="text">{link.label || 'Slides'}</span>
                    </button>
                  {:else if link.youtube}
                    <button on:click={showYoutube(
                      link.youtube,
                      {
                        aspectRatio: toAspectRatio(link.aspectRatio),
                        darkMode: link.darkMode,
                        startTime: link.startTime,
                      }
                    )}>
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#video" />
                      </svg>
                      <span class="text">{ link.label || 'Video' }</span>
                    </button>
                  {:else if link.vimeo}
                    <button on:click={showVimeo(
                      link.vimeo,
                      {
                        aspectRatio: toAspectRatio(link.aspectRatio),
                        darkMode: link.darkMode,
                      }
                    )}>
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#video" />
                      </svg>
                      <span class="text">{ link.label || 'Recorded Presentation' }</span>
                    </button>
                  {:else if link.url}
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        class="button-like"
                      >
                      <svg>
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="/assets/images/icons.svg#globe" />
                      </svg>
                      <span class="text">{ link.label || 'More Details' }</span>
                    </a>
                  {/if}
                </li>
              {/each}
            </LinkList>
          {/if}
        </div>
      </div>
    </li>
  {/each}
</ol>

<script>
  export let code;
  export let smaller = false;

  let element;

  const select = () => {
    const selectedTextLenth = window.getSelection().toString().length;
    // Allow click+drag selection
    if (selectedTextLenth > 0 && selectedTextLenth < code.length) return;
    // Otherwise select everything
    window.getSelection().selectAllChildren(element);
  };
</script>

<style>
  ::selection {
    color: white;
    background-color: rgba(0, 0, 0, 0.996);
  }

  pre {
    overflow-x: auto;
  }

  .smaller {
    font-size: 0.9rem;
  }
</style>

<pre on:click={select}><code bind:this={element} class:smaller>{code}</code></pre>

<script>
  import { ie11 } from './utils.js';
</script>

<style>
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @supports (display: grid) {
    div {
      display: grid;
      grid-column-gap: 0;
      grid-row-gap: 0;
      grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    }
  }

  .ie11 > * {
    min-width: 12rem;
    width: calc((100% / 3) - 1rem);
  }

  /* 400px */
  @media screen and (min-width: 25em) {
    @supports (display: grid) {
      div {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      }
    }
  }

  /* 480px */
  @media screen and (min-width: 30em) {
    @supports (display: grid) {
      div {
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
      }
    }
  }

  /* 800px */
  @media screen and (min-width: 50em) {
    @supports (display: grid) {
      div {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
      }
    }
  }

  /* 800px */
  @media screen and (min-width: 70em) {
    @supports (display: grid) {
      div {
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
      }
    }
  }

  /* 1280px */
  @media screen and (min-width: 90em) {
    @supports (display: grid) {
      div {
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
      }
    }
  }
</style>

<div class:ie11>
  <slot></slot>
</div>

<script>
  import Background from './Background.svelte';
  import Card from './Card.svelte';
  import FullWidthHeadline from './FullWidthHeadline.svelte';
  import Grid from './Grid.svelte';
  import NavBg from './NavBg.svelte';
  import Section from './Section.svelte';
  import Wrap from './Wrap.svelte';
  import VSpace from './VSpace.svelte';

  import { timeout } from './utils.js';

  export let height;
  export let offsetTop;
  export let active = false;

  const emailMe = async (e) => {
    e.preventDefault();
    await timeout(100);
    window.location = 'mailto:fritz@lekschas.de?subject=[Lekschas.de]';
  };

  const background = '#ffabce';
</script>

<Section
  id="contact"
  background={background}
  bind:height={height}
  bind:offsetTop={offsetTop}
>
  <Background img="/assets/images/map" opacity="0.15" webp/>
  <NavBg background="rgba(255, 171, 206, 0.9)" transition {active} />
  <Wrap>
    <FullWidthHeadline title="Get In Touch!"/>
    <Grid>
      <Card
        title="Twitter"
        icon="gezwitscher"
        href="https://twitter.com/flekschas"
        external
      />
      <Card
        title="LinkedIn"
        icon="linkmal"
        href="https://linkedin.com/in/flekschas/"
        external
      />
      <Card
        title="E-Mail"
        icon="mail"
        onClick={emailMe}
      />
      <Card
        title="GitHub"
        icon="codewurst"
        href="https://github.com/flekschas"
        external
      />
      <Card
        title="Google Scholar"
        icon="gscholar"
        href="https://scholar.google.com/citations?user=v1_FiEgAAAAJ"
        external
      />
      <Card
        title="Mastodon"
        icon="mastodon"
        href="https://vis.social/@flekschas"
        rel="me"
        external
      />
    </Grid>
    <VSpace height="2"/>
  </Wrap>
</Section>

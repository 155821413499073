<script>
  import { afterUpdate, onMount, tick } from 'svelte';

  import { getTextWidth, timeout } from './utils.js';

  export let level = 2;
  export let title = '';

  $: words = title.split(' ');

  let element;
  let fontSize = 4;
  let transition = null;
  let width = 0;
  let wordWidths;
  let wordFontSizes;
  let init = false;

  const setFontSize = async (string, elWidth) => {
    await timeout(0);
    const titleWidth = await getTextWidth(
      title,
      window
        .getComputedStyle(element, null)
        .getPropertyValue('font-family'),
      {
        fontWeight: 900,
        textTransform: 'uppercase',
      }
    );

    fontSize = parseFloat(
      ((elWidth / titleWidth) * 0.999).toFixed(3)
    );
    init = true;
  };

  onMount(() => {
    setFontSize(title, width);
  });

  $: if (width) setFontSize(title, width);
</script>

<style lang="scss">
  @import 'globals';

  h1, h2 {
    position: relative;
    z-index: 2;
    margin: 0 -0.5rem;
    padding: 2rem 0;
    color: inherit;
    font-weight: 900;
  }

  h1, h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    transition: opacity $normal $ease;
  }

  span {
    margin-left: 0.25em;
  }

  span:first-child {
    margin-left: 0;
  }

  .init {
    opacity: 1;
    transition: opacity $normal $ease;
  }

  /* 480px */
  @media screen and (min-width: 30rem) {
    h1, h2 {
      margin: 0 -1rem;
    }
  }

  /* 480px */
  @media screen and (min-width: 30rem) {
    h1, h2 {
      margin: 0 -1rem;
    }
  }

  /* 640px */
  @media screen and (min-width: 40rem) {
    h1, h2 {
      margin: 0 -2rem;
    }
  }

  /* 960px */
  @media screen and (min-width: 60rem) {
    h1, h2 {
      margin: 0 -4rem;
    }
  }
</style>

{#if level === 1}
  <h1
    bind:this={element}
    bind:clientWidth={width}
    class:init
    style="font-size: {fontSize}rem; transition: {transition}"
  >
    {#each words as word}
      <span>{word}</span>
    {/each}
  </h1>
{:else}
  <h2
    bind:this={element}
    bind:clientWidth={width}
    class:init
    style="font-size: {fontSize}rem; transition: {transition}"
  >
    {#each words as word}
      <span>{word}</span>
    {/each}
  </h2>
{/if}
